<template>
  <div class="chat_room_box">
    <div class="chat_room">
      <div class="room_title">
        <div class="left_title">当前在线人数：2人</div>
        <div class="right_title">小三哥</div>
      </div>
      <div class="room_content">
        <div class="left_content">111</div>
        <div class="right_content">
          <div class="chat"></div>
          <div class="input_box">
            <editor :actionType="actionType" :item="replyItem" @publish="getMessage" />
            <!-- <textarea name="" id="" placeholder="请输入聊天内容..."></textarea>
            <div class="send" @click="send">发送</div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import editor from '@/component/editor'
export default {
  name: 'chatRoom',
  data() {
    return {

    }
  },
  components: {
    editor
  },
  mounted() {
  },
  methods: {
    send() {
      console.log('点击了发送-----')
    }
  }
}
</script>

<style lang="scss" scoped>
@import './index.scss'
</style>